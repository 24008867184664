import MDRouter from "@/service/router";
import lowerdev from '@/service/lowerdev';
import redirect from '@/service/redirect';
import { env, domPixelRatio } from 'md-base-tools/env';
import { mapState, mapActions } from "vuex";
import { getActivityList, getMergerList, getHashAddress, cbaMerger, addHashAddress } from "@/api/index";
import { throttle } from "@/utils/tools";
import { goToLogin } from "@/utils/user";
import { showFailToast, showSuccessToast } from "vant";
import { mdToast } from "../../../../utils/tools";
export default {
  computed: {
    ...mapState({
      addressList: state => state.address.addressList,
      ifLogin: state => state.user.ifLogin,
      // 是否登录
      userId: state => state.user.userId,
      token: state => state.user.token
    }),
    isPhone() {
      return env.isModianIos() || env.isModianAndroid();
    },
    ratio() {
      return parseInt(domPixelRatio()) || 1;
    }
  },
  data() {
    return {
      isLogin: false,
      status: '0',
      // 合成未开始
      is_merger: 0,
      // 是否可合成 0、不能合成 1、允许合成
      pro_status: 1,
      // 项目是否结束
      nfts: [{
        img_url: 'https://p6.moimg.net/md/nft/119343CBA/248fb841962a41178572cd6425ada812.png',
        title: 'CAB北京控股'
      }, {
        img_url: 'https://p6.moimg.net/md/nft/119343CBA/e34dec34900f48c1875c3318fba976cd.png',
        title: 'CAB天津先行者'
      }, {
        img_url: 'https://p6.moimg.net/md/nft/119343CBA/c8cbd441286f4b798fe8b73c3585e3ef.png',
        title: 'CAB南京同曦宙光'
      }, {
        img_url: 'https://p6.moimg.net/md/nft/119343CBA/b6ab4ef78f58487dbb99eee6ed9c76be.png',
        title: 'CAB浙江稠州金租'
      }, {
        img_url: 'https://p6.moimg.net/md/nft/119343CBA/92bf7bdfb456495c9a302bb29e59bddc.png',
        title: 'CAB山西汾酒股份'
      }, {
        img_url: 'https://p6.moimg.net/md/nft/119343CBA/3600f5c316fa4e6ea62c888a14a4da0d.png',
        title: 'CAB九台农商银行'
      }],
      // 获取活动藏品信息
      times: 0,
      // 开奖倒计时
      times_show: "",
      timer: null,
      loading: false,
      show: false,
      showEdit: false,
      showPicker: false,
      customFieldName: {
        text: 'name',
        children: 'list'
      },
      open_prize_status: 0,
      // 是否开奖 0、未开奖 1、已开奖
      merger_list: [],
      time_seconds: '',
      // 时间
      address_info: {
        address_name: '',
        address_mobile: "",
        province: '',
        address: ''
      },
      // 添加地址表单
      stock_hash: '',
      address_obj: '',
      address_name: '',
      address_mobile: '',
      province: '',
      city: '',
      county: '',
      address: '',
      loading_time: 0,
      ifAnimate: false,
      timer_prize: null
    };
  },
  beforeMount() {
    if (env.isWxApp()) {
      lowerdev.dynamicScript("https://res.wx.qq.com/open/js/jweixin-1.3.2.js");
    }
    const query = this.$route.query;
    if (query['MDUSERTOKEN']) {
      localStorage.setItem("MDUSERTOKEN", query['MDUSERTOKEN']);
    }
  },
  mounted() {
    document.title = "CBA数字藏品";
    this.checkLogin();
    this.getAddressList();
  },
  unmounted() {
    clearInterval(this.timer);
    clearInterval(this.timer_prize);
  },
  methods: {
    ...mapActions({
      getAddressList: "getAddressList"
    }),
    async getActivity() {
      var res = await getActivityList();
      if (res.status == 0) {
        this.status = res.data.status;
        this.is_merger = res.data.is_merger;
        this.nfts = res.data.nfts;
        this.pro_status = res.data.pro_status;
        this.times = res.data.time_seconds;
        if (res.data.status == 0) {
          this.timer = setInterval(() => {
            this.times--;
            this.times_show = this.getDuration(this.times);
            if (this.times <= 0 && res.data.status == 0) {
              clearInterval(this.timer);
              window.location.reload();
            }
          }, 1000);
        }
        if (this.ifLogin) {
          this.getMerger();
        }
      }
    },
    async getMerger() {
      const {
        status,
        data
      } = await getMergerList();
      if (status == 0) {
        this.merger_list = data.list;
        this.open_prize_status = data.open_prize_status;
        if (data.total > 0 && data.open_prize_status == 0 && data.time_seconds > 0) {
          this.timer_prize = setInterval(() => {
            data.time_seconds--;
            this.time_seconds = this.getDuration(data.time_seconds);
            if (data.time_seconds <= 0) {
              clearInterval(this.timer_prize);
              this.getMerger();
            }
          }, 1000);
        }
      }
    },
    fnThrottle(fn, delay, atleast) {
      let timer = null;
      let previous = null;
      return function () {
        let now = +new Date();
        if (!previous) previous = now;
        if (atleast && now - previous > atleast) {
          fn();
          previous = now;
          clearTimeout(timer);
        } else {
          clearTimeout(timer);
          timer = setTimeout(function () {
            fn();
            previous = null;
          }, delay);
        }
      };
    },
    // 开始合成
    goMerge: throttle(async function () {
      const that = this;
      this.loading_time = 0;
      this.ifAnimate = true;
      this.loading = true;
      this.$nextTick(() => {
        let swipeInfo = that.$refs.swipeInfo;
        let H = Math.floor(swipeInfo.clientHeight / 6);
        function scrollUp() {
          let top = swipeInfo.offsetTop;
          if (top < -(H * 4)) {
            clearInterval(swiper_timer);
            swipeInfo.childNodes[5].style.opacity = 0.2;
          } else if (top < -(H * 3)) {
            swipeInfo.childNodes[4].style.opacity = 0.2;
          } else if (top < -(H * 2)) {
            swipeInfo.childNodes[3].style.opacity = 0.2;
          } else if (top < -H) {
            swipeInfo.childNodes[2].style.opacity = 0.2;
          } else if (top < 0) {
            swipeInfo.childNodes[1].style.opacity = 0.2;
          } else if (top > 0) {
            swipeInfo.childNodes[0].style.opacity = 0.2;
          }
        }
        let swiper_timer = setInterval(scrollUp, 100);
      });
      let num = Math.floor(Math.random() * 2) + 1;
      let res = {};
      await Promise.all([new Promise(resolve => {
        setTimeout(async () => {
          const timer = setInterval(async () => {
            if (that.loading_time >= 100) {
              resolve(true);
              clearInterval(timer);
            } else {
              that.loading_time += 1;
            }
          }, 60);
        }, 4000);
      }), new Promise(resolve => {
        setTimeout(async () => {
          res = await cbaMerger();
          resolve(true);
        }, num * 1000);
      })]);
      if (that.loading_time >= 99) {
        that.loading = false;
        if (res.status == 0) {
          if (res.data) {
            showSuccessToast('合成成功');
          } else {
            showFailToast(res.message);
          }
        } else {
          showFailToast(res.message);
        }
        that.getActivity();
        // that.getMerger()
      }
      this.ifAnimate = false;
    }, 1000),
    async showPopup(stock_hash) {
      this.show = true;
      const {
        status,
        data
      } = await getHashAddress({
        stock_hash: stock_hash
      });
      if (status == 0) {
        this.address_info = data.address;
      }
    },
    showPopupEdit(stock_hash) {
      this.resetAddress();
      this.stock_hash = stock_hash;
      this.showEdit = true;
    },
    onConfirm(value) {
      this.province = value[0].name;
      this.city = value[1].name;
      this.county = value[2].name;
      this.address_obj = this.province + '/' + this.city + '/' + this.county;
      this.showPicker = false;
    },
    closePickerEdit() {
      this.stock_hash = '';
      this.showEdit = false;
    },
    closePicker() {
      this.show = false;
    },
    // 添加收货地址
    addAddress: throttle(async function () {
      const that = this;
      if (!that.address_name) {
        mdToast({
          type: 'waning',
          message: '请输入收件人'
        });
        return;
      }
      if (!that.address_mobile) {
        mdToast({
          type: 'waning',
          message: '请输入中国大陆手机号码'
        });
        return;
      }
      if (!that.address) {
        mdToast({
          type: 'waning',
          message: '请输入详细地址'
        });
        return;
      }
      if (!that.province || !that.city || !that.county) {
        mdToast({
          type: 'waning',
          message: '请选择省/市/区'
        });
        return;
      }
      let obj = {
        stock_hash: that.stock_hash,
        address_name: that.address_name,
        address_mobile: that.address_mobile,
        province: that.province,
        city: that.city,
        county: that.county,
        address: that.address
      };
      const {
        status,
        data,
        message
      } = await addHashAddress(obj);
      if (status == 0) {
        if (data) {
          showSuccessToast('保存成功');
          that.showEdit = false;
        } else {
          showFailToast(message);
        }
      } else {
        showFailToast(message);
      }
      that.getMerger();
    }, 2000),
    getDuration(second) {
      var duration;
      var days = Math.floor(second / 86400);
      var hours = Math.floor(second % 86400 / 3600);
      var minutes = Math.floor(second % 86400 % 3600 / 60);
      var seconds = Math.floor(second % 86400 % 3600 % 60);
      if (days > 0) duration = days + "天" + hours + "小时" + minutes + "分" + seconds + "秒";else if (hours > 0) duration = hours + "小时" + minutes + "分" + seconds + "秒";else if (minutes > 0) duration = minutes + "分" + seconds + "秒";else if (seconds > 0) duration = seconds + "秒";
      return duration;
    },
    // 项目未结束-跳转至项目详情
    /* eslint-disable */
    goToPro() {
      MDRouter.goProInfo('119343');
    },
    // 检测登录信息
    checkLogin() {
      if (this.isPhone) {
        graft.app({
          functionName: "onGetUserInfo",
          functionParams: {},
          callBack: e => {
            var token = '';
            var user_id = '';
            if (e.errorCode == 0) {
              user_id = e.data.user_id ? e.data.user_id : e.data.userId;
              token = e.data.token;
              let icon = e.data.icon || "";
              localStorage.setItem("MDUSERTOKEN", user_id + "#" + token);
              localStorage.setItem("MDUSERINFO", icon);
              this.isLogin = true;
            } else {
              user_id = '';
              token = '';
              localStorage.removeItem("MDUSERTOKEN");
              localStorage.removeItem("MDUSERINFO");
              this.isLogin = false;
            }
            setTimeout(() => {
              this.getActivity();
            });
          },
          webFun: e => {
            redirect.to("login");
          }
        });
      } else if (env.isWxApp()) {
        const query = this.$route.query;
        if (query['MDUSERTOKEN'] && query['MDUSERTOKEN'].length > 3) {
          this.isLogin = true;
        } else {
          this.isLogin = false;
        }
        this.getActivity();
      } else {
        this.isLogin = this.ifLogin;
        this.getActivity();
      }
    },
    goToLogin() {
      goToLogin();
    },
    resetAddress() {
      this.address_name = "";
      this.address_mobile = "";
      this.province = "";
      this.city = "";
      this.county = "";
      this.address = "";
    }
  }
};